import React, { useEffect, useState } from 'react'

const UPDATE_INTERVAL = 100

const Progress: React.FC<{
  duration: number | undefined
  onSeek: (position: number) => void
}> = ({ duration, onSeek }) => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    setProgress(0)
  }, [duration])
  useEffect(() => {
    if (!duration) {
      return
    }
    const timeout = setTimeout(() => {
      setProgress(progress + UPDATE_INTERVAL / duration)
    }, UPDATE_INTERVAL)
    return () => clearTimeout(timeout)
  }, [progress, duration])

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!duration) {
      return
    }
    const targetProgress =
      (event.clientX - (event.target as any).getBoundingClientRect().left) /
      (event.target as any).clientWidth
    onSeek(targetProgress * duration)
    setProgress(targetProgress)
  }

  return (
    <div className='progress' onClick={handleClick}>
      <div
        className='progress--bar'
        style={{ transform: `translateX(${(progress - 1) * 100}%)` }}
      />
    </div>
  )
}

export default Progress
