import React, { useEffect, useState } from 'react'
import './App.scss'
import {
  useSpotifyAuth,
  logout,
  spotifyRequest,
  getPlaylistTracks,
} from './spotify'
import { PLAYLIST_ID } from './config'
import { Device, Playlist, Track, User } from './spotifyTypes'
import Puzzle, { PuzzleResponse } from './Puzzle'
import Answer from './Answer'
import Progress from './Progress'
import { sleep } from './util'
import ErrorMessage from './Error'

function App() {
  const [error, setError] = useState<Error>()
  const loggedIn = useSpotifyAuth()
  const [devices, setDevices] = useState<Array<Device>>()
  const [user, setUser] = useState<User>()
  const [tracks, setTracks] = useState<Array<Track>>()
  const [response, setResponse] = useState<PuzzleResponse>()
  const [correctResponsesCount, setCorrectResponsesCount] = useState<number>(0)
  const [correctTrackIndex, setCorrectTrackIndex] = useState<number>()
  const [playingTrackIndex, setPlayingTrackIndex] = useState<number>()

  useEffect(() => {
    ;(async () => {
      if (!tracks || !correctTrackIndex) {
        return
      }
      if (playingTrackIndex === correctTrackIndex) {
        return
      }
      try {
        const fetchedDevices = await getDevices()
        if (fetchedDevices.length === 0) {
          window.location.href = `spotify:track:0HZlND4giwzgolBpaNIRGV`
          return
        }

        setDevices(fetchedDevices)

        await spotifyRequest<void>(
          `https://api.spotify.com/v1/me/player/play/?device_id=${fetchedDevices[0].id}`,
          {
            uris: [tracks[correctTrackIndex].uri],
          },
          'PUT',
        )

        await sleep(500)

        const { item: playingTrack } = await spotifyRequest<{ item?: Track }>(
          'https://api.spotify.com/v1/me/player/currently-playing',
        )

        const currentPlayingTrackIndex = tracks.findIndex(
          (track) => track.uri === playingTrack?.uri,
        )
        setPlayingTrackIndex(
          currentPlayingTrackIndex >= 0 ? currentPlayingTrackIndex : undefined,
        )
      } catch (error) {
        handleError(error)
      }
    })()
  }, [tracks, correctTrackIndex, playingTrackIndex])

  useEffect(() => {
    if (!response) {
      return
    }
    if (response.correct) {
      setCorrectResponsesCount(correctResponsesCount + 1)
    } else {
      setCorrectResponsesCount(0)
    }
  }, [response])

  useEffect(() => {
    if (correctResponsesCount === 10 && !response) {
      setTimeout(() => window.location.reload(), 10000)
    }
  }, [correctResponsesCount, response])

  const handleError = (error: Error) => {
    console.error(error)
    setError(error)
  }

  const getDevices = async (): Promise<Array<Device>> => {
    try {
      return (
        await spotifyRequest<{
          devices: Array<Device>
        }>(`https://api.spotify.com/v1/me/player/devices`)
      ).devices
    } catch (error) {
      handleError(error)
    }
    return []
  }

  useEffect(() => {
    if (!loggedIn) {
      return
    }

    spotifyRequest<User>(`https://api.spotify.com/v1/me`)
      .then((user) => {
        setUser(user)
      })
      .catch(handleError)

    getPlaylistTracks(PLAYLIST_ID, 5)
      .then((playlistTracks) => setTracks(playlistTracks))
      .catch(handleError)
  }, [loggedIn])

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      getDevices().then((fetchedDevices) => {
        setDevices(fetchedDevices)
      })
    })
  }, [])

  const handleSeek = (position: number): void => {
    spotifyRequest<void>(
      `https://api.spotify.com/v1/me/player/seek/?position_ms=${position.toFixed(
        0,
      )}`,
      {},
      'PUT',
    ).catch(handleError)
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!loggedIn) {
    return (
      <div className='app'>
        <h1>Log in...</h1>
      </div>
    )
  }

  if (!tracks || !user) {
    return (
      <div className='app'>
        {' '}
        <h1>Loading...</h1>
      </div>
    )
  }

  if (correctResponsesCount === 10 && !response) {
    return (
      <div className='app'>
        <h1>Großartig.</h1>
      </div>
    )
  }

  return (
    <div className='app'>
      <header>
        <div>
          {devices && devices.length ? devices[0].name : 'Keine Geräte erkannt'}
        </div>
        <div>
          {new Date().getHours()}:
          {new Date().getMinutes().toString().padStart(2, '0')}
        </div>
        <div onClick={() => logout()}>{user.id}</div>
      </header>
      <main>
        {response ? (
          <Answer
            track={response.track}
            correct={response.correct}
            onContinue={() => setResponse(undefined)}
          />
        ) : (
          <Puzzle
            tracks={tracks}
            handleResponse={setResponse}
            handleCorrectTrack={setCorrectTrackIndex}
          />
        )}
      </main>
      <footer>
        <Progress
          duration={
            playingTrackIndex === undefined
              ? undefined
              : tracks[playingTrackIndex].duration_ms
          }
          onSeek={handleSeek}
        />
      </footer>
    </div>
  )
}

export default App
