import React from 'react'
import ErrorMessage from './Error'

export default class ErrorBoundary extends React.Component<
  {},
  {
    error?: Error
  }
> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }
  render() {
    if (this.state?.error) {
      // You can render any custom fallback UI
      return <ErrorMessage error={this.state.error} />
    }
    return this.props.children
  }
}
