import React, { useEffect, useState } from 'react'
import { Track } from './spotifyTypes'
import AlbumInfo from './AlbumInfo'
import { CHOICES } from './config'
import { randomInt } from './util'

export type PuzzleResponse = { correct: boolean; track: Track }

const Puzzle: React.FC<{
  tracks: Array<Track>
  handleResponse: (response: PuzzleResponse) => void
  handleCorrectTrack: (trackIndex: number) => void
}> = ({ tracks, handleResponse, handleCorrectTrack }) => {
  const [randomTrackIndices, setRandomTrackIndices] = useState<Array<number>>()
  const [playingTrackIndex, setPlayingTrackIndex] = useState<number>()

  useEffect(() => {
    if (!tracks) {
      return
    }
    if (randomTrackIndices) {
      return
    }

    const newRandomNumbers: Array<number> = []
    let index = 0
    while (index < CHOICES) {
      let candidateNumber = randomInt(tracks.length)
      if (
        newRandomNumbers.some(
          (trackIndex) =>
            tracks[trackIndex].album.uri === tracks[candidateNumber].album.uri,
        )
      ) {
        continue
      }
      newRandomNumbers.push(candidateNumber)
      index += 1
    }
    setRandomTrackIndices(newRandomNumbers)

    setPlayingTrackIndex(newRandomNumbers[randomInt(CHOICES)])
  }, [tracks, randomTrackIndices])

  useEffect(() => {
    if (tracks === undefined || playingTrackIndex === undefined) {
      return
    }
    handleCorrectTrack(playingTrackIndex)
  }, [tracks, playingTrackIndex])

  const handleSelected = (trackIndex: number) => {
    handleResponse({
      correct: trackIndex === playingTrackIndex,
      track: tracks[playingTrackIndex!],
    })
    setRandomTrackIndices(undefined)
  }

  return (
    <div className='albums'>
      {randomTrackIndices?.map((randomNumber) => (
        <AlbumInfo
          key={randomNumber}
          track={tracks[randomNumber]}
          onSelect={() => handleSelected(randomNumber)}
        />
      ))}
    </div>
  )
}
export default Puzzle
