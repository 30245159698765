import React from 'react'

import { Track } from './spotifyTypes'
import TrackDetails from './TrackDetails'
import AlbumCover from './AlbumCover'

const AlbumInfo: React.FC<{ track: Track; onSelect: () => void }> = ({
  track,
  onSelect,
}) => (
  <div onClick={() => onSelect()}>
    <AlbumCover track={track} />
    <TrackDetails track={track} />
  </div>
)

export default AlbumInfo
