import { useMemo } from 'react'
import { CLIENT_ID, PLAYLIST_ID } from './config'
import { Playlist, Track } from './spotifyTypes'
import { randomInt } from './util'

const LOCAL_STORAGE_ACCESS = 'access'

const SCOPES = [
  'user-read-playback-state',
  'user-modify-playback-state',
  'user-read-private',
  'user-read-email',
]

let accessToken: string

export const useSpotifyAuth = (): boolean => {
  return useMemo(() => {
    if (window.location.href.includes('access_token')) {
      const params = new URL(window.location.href.replace('#', '?'))
        .searchParams
      const expirationMilliseconds =
        parseInt(params.get('expires_in') as string, 10) * 1000
      const access = {
        token: params.get('access_token'),
        expires: new Date(new Date().getTime() + expirationMilliseconds),
      }
      localStorage.setItem(LOCAL_STORAGE_ACCESS, JSON.stringify(access))
      window.location.href = window.location.href.split('#')[0]
      setTimeout(logout, expirationMilliseconds)
      return false
    }

    const localStorageAccess = localStorage.getItem(LOCAL_STORAGE_ACCESS)
    if (localStorageAccess) {
      const access = JSON.parse(localStorageAccess)
      if (new Date(access.expires) < new Date()) {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS)
      } else {
        accessToken = access.token
        return true
      }
    }

    window.location.href = `https://accounts.spotify.com/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${
      window.location.href
    }&scope=${SCOPES.join('%20')}`
    return false
  }, [])
}

export const logout = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_ACCESS)
  window.location.reload()
}

export const spotifyRequest = async <ApiReturnType>(
  url: string,
  body?: any,
  method?: string,
): Promise<ApiReturnType> => {
  const requestDetails: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
  if (body) {
    requestDetails.method = 'POST'
    requestDetails.headers['Content-Type'] = 'application/json'
    requestDetails.body = JSON.stringify(body)
  }
  if (method) {
    requestDetails.method = method
  }
  const result = await fetch(url, requestDetails)

  if (!result.ok) {
    console.error('Failed spotify request:', result)
    throw new Error(
      `Fetch failed: ${url} - ${result.status}: ${result.statusText}`,
    )
  }

  if (result.headers?.get('Content-Type')?.includes('application/json')) {
    return result.json()
  }

  return undefined as any
}

export const getPlaylistTracks = async (
  playlistId: string,
  limitToAlbumCount?: number,
): Promise<Array<Track>> => {
  const cumulatedTracks: { [trackId: string]: Track } = {}
  const relinkedTrackIds: Array<string> = []
  let nextRequestUrl = `https://api.spotify.com/v1/playlists/${playlistId}/tracks?market=DE`
  while (true) {
    const playlist = await spotifyRequest<Playlist>(nextRequestUrl)

    for (let item of playlist.items) {
      const { track } = item
      if (track.restrictions) {
        continue
      }

      if (track.linked_from) {
        relinkedTrackIds.push(track.id)
      }

      cumulatedTracks[track.id] = track
    }
    if (!playlist.next) {
      break
    }
    nextRequestUrl = playlist.next
  }

  for (
    let relinkedTrackOffset = 0;
    relinkedTrackOffset < relinkedTrackIds.length;
    relinkedTrackOffset += 50
  ) {
    const tracksToRelinkIdsBatch = Array.from(
      Array(Math.min(relinkedTrackIds.length - relinkedTrackOffset, 50)).keys(),
    ).map(
      (relinkedTrackIndex) =>
        relinkedTrackIds[relinkedTrackOffset + relinkedTrackIndex],
    )

    const originalTrackIdsBatch = tracksToRelinkIdsBatch
      .map((trackId) => cumulatedTracks[trackId].linked_from!.id)
      .join(',')

    const originalTracks = await spotifyRequest<{ tracks: Array<Track> }>(
      `https://api.spotify.com/v1/tracks/?ids=${originalTrackIdsBatch}`,
    )
    originalTracks.tracks.forEach(
      (originalTrack, relinkedTrackIndexInBatch) => {
        const trackId = tracksToRelinkIdsBatch[relinkedTrackIndexInBatch]
        cumulatedTracks[trackId].album = originalTrack.album
      },
    )
  }

  const playlistTracks = Object.values(cumulatedTracks)

  if (!limitToAlbumCount) {
    return playlistTracks
  }

  const selectedAlbumIds: Array<string> = []
  while (selectedAlbumIds.length < limitToAlbumCount) {
    const possibleAlbumId =
      playlistTracks[randomInt(playlistTracks.length)].album.id
    if (selectedAlbumIds.includes(possibleAlbumId)) {
      continue
    }
    selectedAlbumIds.push(possibleAlbumId)
  }
  return playlistTracks.filter((track) =>
    selectedAlbumIds.includes(track.album.id),
  )
}
