import React from 'react'

const ErrorMessage: React.FC<{ error: Error }> = ({ error }) => (
  <div className='error--wrapper'>
    <div>
      <pre className='error'>
        {error.name}: {error.message}
        {error.stack}
        {JSON.stringify(error, null, 2)}
      </pre>
      <button type='button' onClick={() => window.location.reload()}>
        Zurücksetzen
      </button>
    </div>
  </div>
)

export default ErrorMessage
