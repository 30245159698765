import { Track } from './spotifyTypes'
import React from 'react'

const TrackDetails: React.FC<{ track: Track; showTrackTitle?: boolean }> = ({
  track: { artists, album, name },
  showTrackTitle,
}) => (
  <div className='album--details'>
    {showTrackTitle && <div className='track-name'>{name}</div>}
    <div className='artist-name'>{artists[0].name}</div>
    <div className='album-name'>{album.name}</div>
    <div>{album.release_date.split('-')[0]}</div>
  </div>
)

export default TrackDetails
