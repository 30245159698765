import React from 'react'

import { Track } from './spotifyTypes'
import TrackDetails from './TrackDetails'
import AlbumCover from './AlbumCover'

const Answer: React.FC<{
  track: Track
  correct: boolean
  onContinue: () => void
}> = ({ track, correct, onContinue }) => (
  <div className='answer'>
    <div>
      <AlbumCover track={track} />
    </div>
    <div className='answer--actions'>
      <span
        className={`material-icons answer--icon ${correct ? 'good' : 'bad'}`}>
        {correct ? 'sentiment_satisfied_alt' : 'sentiment_dissatisfied'}
      </span>
      <TrackDetails track={track} showTrackTitle={true} />
      <button type='button' onClick={onContinue}>
        <span
          role='img'
          aria-label='next'
          className='material-icons answer--icon'>
          skip_next
        </span>
      </button>
    </div>
  </div>
)

export default Answer
